/* .zoom-container {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

}

.zoom-controls {
  z-index: 999 !important;
}

.geneology_container {
  height: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.geneology_container .card-datatable {
  height: inherit;
  max-height: 100%
}

.geneology_container .hv-container {
  min-height: 100%;
  height: 100%;
}

.geneology_container .hv-wrapper {
  height: inherit;
  min-height: 100%;
}

.geneology_container .hv-wrapper .hv-item {
  margin: 0;
}

.geneology_container .hv-wrapper .table-responsive {
  height: 100%;
}

.hv-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.hv-wrapper .hv-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

.hv-wrapper .hv-item .hv-item-parent {
  margin-bottom: 50px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

.hv-wrapper .hv-item .hv-item-parent:has(+.hv-item-children):after {
  position: absolute;
  content: '';
  width: 2px;
  height: 25px;
  bottom: 0;
  left: 50%;
  background-color: #fff;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.hv-wrapper .hv-item .hv-item-parent:has(+.hv-item-children:empty):after {
  opacity: 0;
}

.hv-wrapper .hv-item .hv-item-parent:after {
  position: absolute;
  content: '';
  width: 2px;
  height: 25px;
  bottom: 0;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.hv-wrapper .hv-item .hv-item-children {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  place-content: flex-start;
}

.hv-wrapper ul.pagination {
  gap: 1.5rem;
  margin-left: 1.5rem;
  position: absolute;
  bottom: 7.6rem;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child {
  padding: 0 15px;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:before,
.hv-wrapper .hv-item .hv-item-children .hv-item-child:after,
.hv-item .hv-item-parent:before {
  content: '';
  position: absolute;
  background-color: #fff;
  left: 0;
}

.hv-wrapper .hv-item .hv-item-parent>a {
  color: #505c92 !important;
}

.table-responsive>.hv-item-parent::before {
  display: none;
}

.hv-item .hv-item-parent:first-child:before {
  display: none;
}

.hv-item-children .hv-item-child .hv-item-parent {
  position: relative;
}

.hv-item-children .hv-item-child .hv-item-parent:before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 2px;
  height: 25px;
  background: #000;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:before,
.hv-item .hv-item-parent:before {
  left: 50%;
  top: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 2px;
  height: 25px;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:after {
  top: -25px;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  height: 2px;
  width: 100%;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:first-child::after {
  left: 50%;
  width: 50%;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:only-child {
  max-width: 50%;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:last-child::after {
  width: calc(50% + 1px);
}

.hv-wrapper .hv-item .hv-item-children .hv-item .hv-item-children {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
  display: table;
  width: 185px;
  margin: 0 auto;
}

.hv-wrapper .hv-item .hv-item .hv-item-children .hv-item-child::after {
  width: 14px;
  left: 20px;
  top: 35px;
}

.hv-wrapper .hv-item .hv-item .hv-item-children .hv-item-child::before {
  height: 88px;
  left: 20px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  top: -25px;
}

.hv-wrapper .hv-item .hv-item .hv-item-children .hv-item-child {
  padding: 0;
}

.hv-wrapper .hv-item .hv-item .hv-item-children .hv-item-child:last-child::before {
  height: 58px;
}

.hv-wrapper .hv-item .hv-item-parent .tree-node-dropdown,
.hv-wrapper .hv-item .hv-item-children .hv-item-child>a .tree-node-dropdown {
  position: absolute;
  width: 250px;
  padding: 15px 15px 0 15px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 2;
  text-align: left;
  background: rgba(44, 52, 63, .8);
  border-radius: 5px;
  border-bottom: 2px solid #2C343F;
  color: #fff;
  top: 100%;
  left: -25px;
}

.tree-node-dropdown::before {
  width: 0;
  height: 0;
  content: "";
  bottom: 100%;
  left: 50%;
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 9px solid rgba(44, 52, 63, .8);
}

.hv-wrapper .hv-item .hv-item-parent>a:hover>.tree-node-dropdown,
.hv-wrapper .hv-item .hv-item-children .hv-item-child>a:hover>.tree-node-dropdown {
  opacity: 1;
  visibility: visible;
}

.tree-node-dropdown p {
  margin-bottom: 15px;
}

.hv-wrapper .hv-item .hv-item-parent>a,
.hv-wrapper .hv-item .hv-item-children .hv-item-child>a {
  display: inline-block;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px #ccc;
  box-shadow: 0 0 4px #ccc;
  border-bottom: 2px solid #eee;
  padding: 15px;
  text-align: center;
  min-width: 150px;
  position: relative;
}

.hv-wrapper .hv-item .hv-item-parent>a img {
  display: table;
  margin: 0 auto 10px;
  max-width: 70px;
  height: auto;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child>a {
  text-align: left;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child>a img {
  display: inline-block;
  margin: 0 auto;
  max-width: 40px;
}

.hv-container .block-content {
  padding: 10px 20px;
}

.hv-wrapper .hv-item .hv-item-children {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-content: center;
  width: 100%;
} */


.level {
  border: 1px solid #452d60;
  text-align: center;
  padding: 20px;
  margin: 20px;
  font-size: 1.3rem;
  color: #ffffff;
  border-radius: 0.5rem;
  background: #261935;
}