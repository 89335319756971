@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.shimmer-line {
  height: 20px;
  margin: 1px 0;
  border-radius: 4px;
  background: radial-gradient(
    circle,
    rgba(50, 29, 61, 1) -30%,
    /* Color 1 */ rgba(27, 22, 46, 1) 100% /* Color 2 */
  );
  background-size: 200% 100%; /* This is important to allow the shimmering effect */
  animation: shimmer 1.5s infinite linear; /* Apply the shimmer animation */
}
